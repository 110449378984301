//upLoaderImg.js
import axios from 'axios'		//引入axios
import { Toast } from 'vant'	//引入Toast

function upLoaderImg (file) {	//file为 你读取成功的回调文件信息
                               //new 一个FormData格式的参数
  let params = new FormData()
  params.append('file', file)
  let config = {
    headers: { //添加请求头
      'Content-Type': 'multipart/form-data'
    }
  }
  return new Promise((resolve, reject) => {
    //把 uploadUrl 换成自己的 上传路径
    axios.post('https://888.bjjoyo.com/prod-api/system/oss/scenario/upload', params, config).then(res => {
      resolve(res.data)
     });
  })
}
export default upLoaderImg

