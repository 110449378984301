<template>
  <div class="personalDetails">
    <top-bar title="个人信息" hasBack></top-bar>
    <van-form :colon="true" @submit="submit">
      <van-field
          v-model="wxUserInfo.nickname"
          label="昵称"
          :border="false"
          size="large"
          required
          :rules="rules.nickname"
          class="van-filed"
      />
      <van-field name="radio"
                 label="性别"
                 size="large"
                 required
                 v-model="wxUserInfo.sex"
                 :rules="rules.sex"
                 class="van-filed"
      >
        <template #input>
          <van-radio-group v-model="wxUserInfo.sex" @change="onChange" direction="horizontal">
            <van-radio name="0">男</van-radio>
            <van-radio name="1">女</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field required
                 name="uploader"
                 label="头像"
                 size="large"
                 v-model="wxUserInfo.avatar"
                 readonly
                 class="van-filed">
        <template #input>
          <van-uploader
              v-model="avatarList"
              max-count="1"
              :after-read="afterRead"
              :before-read="beforeRead"
              :before-delete="delImg"
          />
        </template>
      </van-field>
      <van-field
          v-model="wxUserInfo.smsVerifyCode"
          size="large"
          label="短信验证码"
          required
          :rules="rules.smsVerifyCode"
          v-if="isTelDiff"
          border
          class="van-filed"
      >
        <template #button>
          <van-button v-if="isCodeShow" size="small" color="#6192D6" @click="sendCode(wxUserInfo.phoneNum)">{{
              sendMsg
            }}
          </van-button>
          <van-button v-if="!isCodeShow" size="small" color="#6192D6" disabled>{{ sendMsg }}</van-button>
        </template>
      </van-field>
      <div style="margin: 16px;">
        <van-button block color="#2b8df7" native-type="submit">
          提交修改
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import TopBar from '@/components/TopBar'
import ajax from '@/api'
import {Toast} from 'vant'
import upLoaderImg from '@/utils/upLoaderImg'
import {userIdCache} from '@/assets/js/cache'

export default {
  name: 'personalDetails',
  inject: ['reload'],
  components: {TopBar},
  data() {
    return {
      avatarList: [],
      wxUserInfo: {},
      rules: {
        'nickname': [
          {required: true, message: '昵称不能为空'}],
        'smsVerifyCode': [
          {required: true, message: '验证码不能为空'}
        ],
        'sex': [
          {required: true, message: '性别不能为空'}
        ]
      },
      isCodeShow: true,
      sendMsg: '发送验证码',
      isTelDiff: false,
      myTel: null
    }
  },
  created() {
    this.getList()
  },

  methods: {
    // 删除图片前的回调，可以返回Promise
    delImg(file, detail) {
      return new Promise((resolve, reject) => {
        this.$dialog.confirm({
          message: '确认删除图片？'
        }).then(() => {
          // 存放图片的数组
          this.avatarList.splice(detail.index, 1)
          this.wxUserInfo.avatar = null
          this.$toast.success('删除成功')
          resolve()
        }).catch(error => {
          this.$toast.fail('已取消')
          reject(error)
        })
      })
    },

    beforeRead(file) {	//上传之前校验
      if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
        Toast('只允许上传jpg/png格式的图片！')
        return false
      }
      return true
    },
    //文件读取完成后的回调函数
    async afterRead(file) {
      let uploadImg = await upLoaderImg(file.file)//使用上传的方法。file.file
      this.avatarList.splice(0, 1, {url: uploadImg.data.url, isImage: true})
      this.wxUserInfo.avatar = uploadImg.data.url
    },

    getList() {
      ajax.getWxUserInfo({
        userId: userIdCache.getCache(),
      }).then((res) => {
        this.wxUserInfo = res.data || {}
        this.avatarList.push({
          url: res.data.avatar,
          isImage: true
        })
      })
    },
    // sendCode(phoneNum){
    //   ajax.sendCode({phoneNum}).then((res) => {
    //     if (res.code == 200){
    //       this.$toast.success('验证码发送成功');
    //       let endMsRes = new Date().getTime() + 60000; //当前时间戳加上一分钟的时间戳，相当于当前时间一分钟以后的时间戳
    //       this.time(endMsRes)
    //       this.isCodeShow = true
    //     } else {
    //       this.$toast.fail(res.msg);
    //       this.isCodeShow = true
    //     }
    //   })
    // },
    //验证码倒计时
    time(endMsRes) {
      this.isCodeShow = false
      this.countDown = Math.ceil((endMsRes - new Date().getTime()) / 1000)
      this.sendMsg = this.countDown + 's'
      this.timeFun = setInterval(() => {
        this.countDown--
        this.sendMsg = this.countDown + 's'
        if (this.countDown < 1) {
          this.isCodeShow = true
          this.sendMsg = '重新发送'
          this.countDown = 60
          localStorage.removeItem('loginSendTime')
          clearInterval(this.timeFun)
        }
      }, 1000)
    },
    submit() {
      ajax.updateWxUserInfo(this.wxUserInfo).then((res) => {
        if (res.code == 200) {
          this.$toast.success('个人信息修改成功！')
          // this.getList()
          this.isTelDiff = false
        } else {
          this.$toast.fail(res.msg)
        }
      })
    },
    onChange(event) {
      this.wxUserInfo.sex = event
    }
  },
  watch: {
    // 'wxUserInfo.phoneNum'(newV,oldVal) {
    //   if ( newV !== this.myTel && this.myTel !== undefined){
    //     this.isTelDiff = true
    //   }else{
    //     this.isTelDiff = false
    //   }
    // },

  },
}
</script>
<style lang="scss" scoped>
@import "style";
</style>
